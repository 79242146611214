<template>
  <b-card>
    <div class="fama-table">
      <div v-if="dataLoading" class="spinner-border m-auto" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div v-else-if="error.length !== 0" class="m-auto">
        {{ error }}
      </div>
      <div v-else-if="error.length === 0" style="width: 100%">
        <DxDataGrid
            v-if="can_list"
            id="boq-table"
            ref="data-grid"
            width="100%"
            :show-borders="true"
            :data-source="databoq"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            column-resizing-mode="widget"
            :column-auto-width="true"
            :column-auto-height="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :row-alternation-enabled="true"
            :hover-state-enabled="true"
            :word-wrap-enabled="true"
            key-expr="id"
            exporting="exportGrid"
            @init-new-row="onInserting"
            @row-inserted="onInserted"
            @row-updated="onUpdated"
            @exporting="onExporting"
            @onexported="onExported"
            @editor-preparing="onEditorPreparing"
        >

          <DxExport :enabled="true"/>

          <DxColumn data-field="id" caption="ID" :allow-editing="false">
            <DxFormItem :visible="false"/>
          </DxColumn>


          <DxColumn data-field="photo1" cell-template="grid-cell-template" :visible="false"></DxColumn>
          <DxColumn data-field="photo2" cell-template="grid-cell-template" :visible="false"></DxColumn>
          <DxColumn data-field="photo3" cell-template="grid-cell-template" :visible="false"></DxColumn>
          <template #grid-cell-template="{ data }">
            <div>
              <img :src="data.value" height="100" width="100">
            </div>
          </template>

          <template #diff-cell-template1="{ data }">
            <img
                :v-bind:src=" 'data:image/png;base64,' + blobToBase64(data.data.work_problem_images.length > 0 ?data.data.work_problem_images[0].image.data : data.data.work.work_images[0].image.data)"
                height="100" width="100"/>
          </template>
          <template #diff-cell-template2="{ data }">
            <img
                :v-bind:src=" 'data:image/png;base64,' + blobToBase64(data.data.work_problem_images.length > 0 ?data.data.work_problem_images[1].image.data : data.data.work.work_images[1].image.data)"
                height="100" width="100"/>
          </template>
          <template #diff-cell-template3="{ data }">
            <img
                :v-bind:src=" 'data:image/png;base64,' + blobToBase64(data.data.work_problem_images.length > 0 ?data.data.work_problem_images[2].image.data : data.data.work.work_images[2].image.data)"
                height="100" width="100"/>
          </template>
          <DxColumn
              data-field="type"
              :name="$t('problem.type')"
              :caption="$t('problem.type')"
          />
          <DxColumn
              data-field="created_at"
              data-type="date"
              format="dd.MM.yyyy"
              :name="$t('problem.created_at')"
              :caption="$t('problem.created_at')"
          />
          <DxColumn
              data-field="firm_name"
              :name="$t('problem.work_firm_name')"
              :caption="$t('problem.work_firm_name')"
          />
          <DxColumn
              data-field="chiefName"
              :visible="false"
              :name="$t('problem.responsible')"
              :caption="$t('problem.responsible')"
          >

          </DxColumn>
          <DxColumn
              data-field="activity_code"
              :visible="false"
              :name="$t('problem.activity_code')"
              :caption="$t('problem.activity_code')"
          />
          <DxColumn
              data-field="wbs_translation_mobile_name"
              :name="$t('problem.wbs_translation_mobile_name')"
              :caption="$t('problem.wbs_translation_mobile_name')"
          />
          <DxColumn
              data-field="activity_name"
              :name="this.$t('activity.activityName')"
              :caption="this.$t('activity.activityName')"
          />
          <DxColumn
              data-field="problem_group"
              :name="$t('problem.problemGroup')"
              :caption="$t('problem.problemGroup')"
          />
          <DxColumn
              data-field="code"
              :caption="this.$t('wbs.wbsCode')"
              :name="this.$t('wbs.wbsCode')"
              :visible="false"
          />
          <DxColumn
              data-field="problem"
              :name="$t('problem.problem')"
              :caption="$t('problem.problem')"
          />
          <DxColumn
              data-field="explanation"
              :visible="true"
              :caption="$t('problem.explanation')"
              :name="$t('problem.explanation')"
          >
            <DxFormItem
                :col-span="1"
                :editor-options="{ height: 100 }"
                editor-type="dxTextArea"
            />
          </DxColumn>
          <DxColumn
              data-field="actions"
              :name="$t('problem.actions')"
              :caption="$t('problem.actions')"
          >
            <DxFormItem
                :col-span="2"
                :editor-options="{ height: 100 }"
                editor-type="dxTextArea"
            />
          </DxColumn>
          <DxColumn
              data-field="is_resolved"
              data-type="boolean"
              :name="$t('table.is_resolved')"
              :caption="$t('table.is_resolved')"
          />
          <DxColumn
              data-field="resolved_at"
              data-type="date"
              format="dd.MM.yyyy"
              :name="$t('problem.resolved_at')"
              :caption="$t('problem.resolved_at')"
          />
          <DxColumn
              data-field="spentTime"
              data-type="number"
              :name="$t('problem.Day')"
              :caption="$t('problem.Day')"
          />

          <DxColumn
              data-field="problem_id"
              :visible="false"
              :name="$t('problem.problem_id')"
              :caption="$t('problem.problem_id')"
          />

          <DxColumn
              data-field="type_id"
              :visible="false"
              :name="$t('problem.type_id')"
              :caption="$t('problem.type_id')"
          />
          <DxColumn
              data-field="submitter.username"
              :name="$t('problem.submitter')"
              :caption="$t('problem.submitter')"
          />
          <DxFilterRow :visible="true"/>
          <DxSearchPanel :visible="true"/>
          <DxColumnFixing :enabled="true"/>
          <DxColumnChooser :enabled="true"/>
          <DxScrolling mode="standard"/>
          <DxGrouping :context-menu-enabled="true"/>
          <DxGroupPanel :visible="true"/>
          <DxPaging :page-size="20"/>

          <DxPager
              :visible="true"
              :allowed-page-sizes="pageSizes"
              :show-page-size-selector="showPageSizeSelector"
              :show-info="showInfo"
              :show-navigation-buttons="showNavButtons"
          />
          <DxEditing
              :allow-updating="false"
              :allow-deleting="false"
              :allow-adding="can_create"
              mode="row"
              :select-text-on-edit-start="true"
              :use-icons="true"
              :word-wrap-enabled="true"
          />
          <DxColumn
              type="buttons"
              :allow-exporting="false"
              :caption="$t('table.actions')"
          >
            <DxButton
                hint="Edit"
                icon="edit"
                :visible="can_edit"
                :disabled="!can_edit"
                :on-click="onEditing"
            />
            <DxButton
                hint="Delete"
                icon="trash"
                :visible="can_delete"
                :disabled="!can_delete"
                :on-click="onRemoved"
            />
            <DxButton
                hint="Photo"
                icon="photo"
                :visible="photoIconVisible"
                :disabled="false"
                :on-click="photoIconClick"
            />
          </DxColumn>

          <DxSummary>
            <DxGroupItem
                column="id"
                summary-type="count"
            />
          </DxSummary>
        </DxDataGrid>
        <h1 v-else>{{ $t('noPermission') }}</h1>
        <DxPopup
            :visible="addPopupVisible"
            :close-on-outside-click="false"
            :show-close-button="false"
            :title="$t(newGeneralProblem.id ? 'updatingNewGeneralProblem' : 'problem.problemEdit')"
          
        >
        <div style='height: 99%; overflow: scroll;'>
          <div class="dx-field">
            <div class="dx-field-label">Problem Group</div>
            <div class="dx-field-value">
              <DxSelectBox
                  v-model="newGeneralProblem.problemGroup"
                  :search-enabled="true"
                  :items="dataProblemGroup"
                  display-expr="name"
                  value-expr="id"
                  @value-changed="problemGroupChange"
              />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Problem</div>
            <div class="dx-field-value">
              <DxSelectBox
                  v-model="newGeneralProblem.problemTypeFiltered"
                  :search-enabled="true"
                  :items="dataProblemTypeFiltered"
                  display-expr="name"
                  value-expr="id"
              />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Explanation</div>
            <div class="dx-field-value">
              <DxTextArea :min-height="120" v-model="newGeneralProblem.explanation"></DxTextArea>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Actions</div>
            <div class="dx-field-value">
              <DxTextArea :min-height="120" v-model="newGeneralProblem.actions"></DxTextArea>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Resolved</div>
            <div class="dx-field-value">
              <DxCheckBox
                  :value="null"
                  :icon-size="40"
                  v-model="newGeneralProblem.resolved"
              />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-value">
              <b-button
                  class="btn-import mr-1"
                  variant="danger"
                  @click="closeAddingPop()"
              >Close
              </b-button
              >
              <b-button
                  class="btn-import mr-1"
                  variant="success"
                  @click="saveNewGeneralProblem()"
              >{{ newGeneralProblem.id > 0 ? 'Update' : 'Save' }}
              </b-button
              >
            </div>
          </div>
          </div>
        </DxPopup>
        <div v-if="photoModel != null">
          <DxPopup
              :visible="photoPop"
              :close-on-outside-click="false"
              :show-close-button="false"
              title="Photos"
          >
            <DxToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                :options="photoPopButton"
            />

            <div style="width: 100%" class="row">
              <div class="col-md-4">
                <img :src="photoModel.photo1">
              </div>
              <div class="col-md-4">
                <img :src="photoModel.photo2">
              </div>
              <div class="col-md-4">
                <img :src="photoModel.photo3">
              </div>

            </div>
          </DxPopup>
        </div>
      </div>
    </div>

    <b-modal
        ref="import-modal"
        centered
        title="Import"
        ok-only
        :ok-title="$t('buttons.ok')"
    >
      <b-card-text>{{ $t('general.importStarted') }}</b-card-text>
      <b-card-text v-if="importResult.length !== 0">
        {{ importResult }}
      </b-card-text>
      <div class="d-flex justify-content-center">
        <b-spinner v-if="importResult.length === 0"/>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import axios from '@axios';
import {
  BCard,
  BButton,
  BModal,
  BCardText,
  BSpinner
} from 'bootstrap-vue';

import {
  DxDataGrid,
  DxColumn,
  DxExport,
  DxScrolling,
  DxColumnFixing,
  DxSearchPanel,
  DxColumnChooser,
  DxGroupPanel,
  DxGrouping,
  DxFilterRow,
  DxPaging,
  DxEditing,
  DxFormItem,
  DxButton,
  DxPager,
  DxGroupItem,
  DxSummary,
} from 'devextreme-vue/data-grid';
import {DxTextArea} from 'devextreme-vue/text-area';
import {DxCheckBox} from 'devextreme-vue/check-box';
import {DxSelectBox} from 'devextreme-vue/select-box';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {Workbook} from 'exceljs';
import saveAs from 'file-saver';
import 'jspdf-autotable';
import {DxPopup, DxToolbarItem} from 'devextreme-vue/popup';
import {readOnly} from "@/auth/utils";

let problemListF = [];
export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BModal,
    BSpinner,
    DxEditing,
    DxGroupItem,
    DxSummary,
    DxFilterRow,
    DxColumn,
    DxColumnFixing,
    DxDataGrid,
    DxScrolling,
    DxSearchPanel,
    DxColumnChooser,
    DxGroupPanel,
    DxGrouping,
    DxPaging,
    DxExport,
    DxFormItem,
    DxPopup,
    DxSelectBox,
    DxTextArea,
    DxCheckBox,
    DxButton,
    DxPager,
    DxToolbarItem
  },
  data() {
    return {
      can_edit: false,
      can_list: false,
      can_create: false,
      can_delete: false,
      pageSizes: [20, 50, 100, 200],
      showPageSizeSelector: true,
      showInfo: true,
      showNavButtons: true,
      enterKeyActions: ['startEdit', 'moveFocus'],
      enterKeyDirections: ['none', 'column', 'row'],
      enterKeyDirection: 'row',
      enterKeyAction: 'moveFocus',
      dataProblemGroup: [],
      dataProblemType: [],
      dataProblemTypeFiltered: [],
      dataUnit: [],
      dataUsers: [],
      dataActivity: [],
      dataPositions: [],
      dataCurrency: [],
      databoq: [],
      languages: [],
      selectedLanguage: {},
      dataLoading: true,
      error: '',
      photoModel: null,
      file: null,
      importResult: 'deneme',
      addPopupVisible: false,
      photoPop: false,
      newGeneralProblem: {
        id: 0,
        problemGroup: 0,
        problemTypeFiltered: 0,
        resolved: false,
        explanation: '',
        actions: '',
        type: ''
      },
      photoPopButton: {
        text: 'Close',
        onClick: () => {
          this.photoModel = null
          this.photoPop = false;
        }
      },
      problemTypes: [{id: 1, name: 'General'}],
      readOnly: false
    };
  },
  async mounted() {
    this.readOnly = readOnly();
    await this.getWbsData();
    await this.getPageAuths();
    await this.getLanguage();
    await this.getUnit();
    await this.getUsers();
    await this.getBoqData();
    await this.getCurrency();
    await this.getProblemGroup();
    await this.getProblemType();
  },
  methods: {
    photoIconClick(e) {
      this.photoModel = e.row.data
      this.photoPop = true

    }, photoIconVisible(e) {
      return !(e.row.data.photo1 == null && e.row.data.photo2 == null && e.row.data.photo3 == null)

    },

    async getPageAuths() {
      const pageAuths = await axios.post('/get-page-auth', {
        page: 'Problems'
      });
      this.can_edit = this.readOnly && pageAuths.data.can_edit;
      this.can_list = pageAuths.data.can_list;
      this.can_delete = this.readOnly && pageAuths.data.can_delete;
      this.can_create = this.readOnly && pageAuths.data.can_create;
    },
    async getWbsData() {
      this.dataLoading = true;
      this.error = '';
      const response = await axios.get('/list-wbs');

      try {
        this.dataWbs = response?.data?.result || [];
        this.dataLoading = false;
      } catch (error) {
        this.dataLoading = false;

        this.error = e?.response?.data?.message || e?.message;
      }
    }, async getActivityData() {
      this.dataLoading = true;
      this.error = '';
      const response = await axios.get('/list-activity');

      try {
        this.dataActivity = response?.data?.result || [];
        this.dataLoading = false;
      } catch (error) {
        this.dataLoading = false;

        this.error = e?.response?.data?.message || e?.message;
      }
    },
    closeAddingPop() {
      this.addPopupVisible = false;
      this.newGeneralProblem = {
        id: 0,
        problemGroup: 0,
        problemTypeFiltered: 0,
        resolved: false,
        explanation: '',
        actions: '',
        type: '',
        type_id: ''
      };
    },

    async saveNewGeneralProblem() {
      if (this.newGeneralProblem.type_id === 2) {
        let response = await axios
            .post(
                `edit-work-problem/${this.newGeneralProblem.id}`,
                this.newGeneralProblem
            );
        await this.getBoqData();
        // axios
        //     .post(
        //         `edit-work-problem/${this.newGeneralProblem.id}`,
        //         this.newGeneralProblem
        //     )
        //     .then((response) => {
        //       this.showToast({
        //         variant: 'success',
        //         text: 'Veri başarıyla güncellendi.'
        //       });
        //       // kapo
        //       this.getBoqData().then();
        //     })
        //     .catch((err) => {
        //       this.showToast({
        //         variant: 'danger',
        //         text: err?.response?.data?.message || err?.message
        //       });
        //     });
      } else {
        if (this.newGeneralProblem.id === 3) {
          axios
              .post(`create-general-problem`, this.newGeneralProblem)
              .then((response) => {
                this.showToast({
                  variant: 'success',
                  text: 'Veri başarıyla eklendi.'
                });
                this.getBoqData();
              })
              .catch((err) => {
                this.showToast({
                  variant: 'danger',
                  text: err?.response?.data?.message || err?.message
                });
              });
        } else {
          axios
              .put(
                  `edit-general-problem/${this.newGeneralProblem.id}`,
                  this.newGeneralProblem
              )
              .then((response) => {
                this.showToast({
                  variant: 'success',
                  text: 'Veri başarıyla güncellendi.'
                });
                this.getBoqData();
              })
              .catch((err) => {
                this.showToast({
                  variant: 'danger',
                  text: err?.response?.data?.message || err?.message
                });
              });
        }
      }
    },
    async getProblemType() {
      this.error = '';
      const response = await axios.get('/list-problems');

      try {
        this.dataProblemType = response?.data?.result || [];
        problemListF = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getProblemGroup() {
      this.error = '';
      const response = await axios.get('/list-problem-type');

      try {
        this.dataProblemGroup = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    problemTypeChange(e) {
    },
    problemGroupChange(e) {
      this.dataProblemTypeFiltered = this.dataProblemType.filter(
          (f) => f.type_id === e.value
      );
    },
    isDisabledEdit(e) {
      return !(e.row.data.type_id === 2);
    },
    onEditorPreparing(e) {
      if (e.dataField === 'type') {
        // e.editorOptions.visible = false;
      }
    },
    async getLanguage() {
      this.error = '';
      const response = await axios.get('/list-language');

      try {
        this.languages = response?.data?.result || [];
        const selected = this.languages.find(
            (x) => x?.code === localStorage.dbLanguage
        );
        this.selectedLanguage = selected;
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getUnit() {
      this.error = '';
      const response = await axios.get('/list-unit');

      try {
        this.dataUnit = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getUsers() {
      this.error = '';
      const response = await axios.get('/list-position');

      try {
        this.dataPositions = response?.data || [];

      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getCurrency() {
      this.error = '';
      const response = await axios.get('/list-currency');

      try {
        this.dataCurrency = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getBoqData() {
      this.dataLoading = true;
      this.addPopupVisible = false;
      this.newGeneralProblem = {
        id: 0,
        problemGroup: 0,
        problemTypeFiltered: 0,
        resolved: false,
        explanation: '',
        actions: '',
        type: '',
        type_id: '',
      };
      this.error = '';
      const response = await axios.get('/list-work-all-problem');

      try {
        this.databoq = response?.data?.result || [];
        let chiefs = response?.data?.chiefs || []
        let positions = response?.data?.positions || []
        this.databoq.forEach(d => {
          if(d.work){
              d.chiefPosId = positions.find(c => c.id == d.work.activity.responsible_position)
              d.chiefName = ""
              if (d.chiefPosId) {
                  let q = chiefs.find(c => c.id == d.chiefPosId.chief_position_id)
                  if (q) {
                      d.chiefName = q.name
                  }
              }
          }
          if(d.chiefName == "subc"){
              d.chiefPosId = positions.find(c => c.id == d.submitter.position_id)
              if (d.chiefPosId) {
                  let q = chiefs.find(c => c.id == d.chiefPosId.chief_position_id)
                  if (q) {
                      d.chiefName = q.name
                  }
              }
          }
          d.type = this.$t(d.type)
            console.log(`d.is_resolved ${d.is_resolved}`)
          if (d.is_resolved !== true) {
            d.resolved_at = null
              // d.spentTime = 0
              var diff = Math.abs(new Date().getTime() - new Date(d.created_at).getTime());
              var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
              // console.log(`diffDays ${diffDays}`)
              d.spentTime = diffDays
          } else {
              var diff = Math.abs(new Date(d.resolved_at).getTime() - new Date(d.created_at).getTime());
              var diffDays = Math.ceil(diff / (1000 * 3600 * 24));

          }
            d.spentTime = diffDays +1
          // if (d.spentTime == null) {
          //
          // } else {
          //     console.log("qqqqqqq")
          //     d.spentTime += 1
          // }

          if (d.work_problem_images !== undefined && d.work_problem_images.length > 0) {
            d.photo1 = d.work_problem_images[0] ? 'data:image/png;base64,' + this.blobToBase64(d.work_problem_images[0].image.data) : null
            d.photo2 = d.work_problem_images[1] ? 'data:image/png;base64,' + this.blobToBase64(d.work_problem_images[1].image.data) : null
            d.photo3 = d.work_problem_images[2] ? 'data:image/png;base64,' + this.blobToBase64(d.work_problem_images[2].image.data) : null
          } else {
            if (d.work !== undefined && d.work != null && d.work.work_images !== undefined) {
              d.photo1 = d.work.work_images[0] ? 'data:image/png;base64,' + this.blobToBase64(d.work.work_images[0].image.data) : null
              d.photo2 = d.work.work_images[1] ? 'data:image/png;base64,' + this.blobToBase64(d.work.work_images[1].image.data) : null
              d.photo3 = d.work.work_images[2] ? 'data:image/png;base64,' + this.blobToBase64(d.work.work_images[2].image.data) : null
            } else {
              d.photo1 = null
              d.photo2 = null
              d.photo3 = null
            }

          }
        })
        this.dataLoading = false;
      } catch (e) {
        this.dataLoading = false;
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async refreshList() {
      this.dataLoading = true;
      await this.getWbsData();
      await this.getLanguage();
      await this.getUnit();
      await this.getBoqData();
      await this.getCurrency();
    },
    showToast({variant = 'success', title, text, icon = 'XIcon'}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant
        }
      });
    },
    onExported: function (e) {
      e.component.columnOption("photo1", "visible", false);
      e.component.columnOption("photo2", "visible", false);
      e.component.columnOption("photo3", "visible", false);
      e.component.endUpdate();
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Fama-GeneralProblem');
      e.component.beginUpdate();
      e.component.columnOption("photo1", "visible", true);
      e.component.columnOption("photo2", "visible", true);
      e.component.columnOption("photo3", "visible", true);
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function ({gridCell, excelCell}) {
          excelCell.font = {name: 'Arial', size: 10};
          excelCell.alignment = {horizontal: 'left'};

          if (gridCell.rowType === 'data') {
            if (gridCell.column.dataField === 'photo1' || gridCell.column.dataField === 'photo2' || gridCell.column.dataField === 'photo3') {
              excelCell.value = undefined;

              if (gridCell.value != null) {
                const image = workbook.addImage({
                  base64: gridCell.value,
                  extension: 'png',
                });

                worksheet.getRow(excelCell.row).height = 90;
                worksheet.addImage(image, {
                  tl: {col: excelCell.col - 1, row: excelCell.row - 1},
                  br: {col: excelCell.col, row: excelCell.row},
                });
              }

            }
          }
        }
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
              new Blob([buffer], {type: 'application/octet-stream'}),
              'Fama-GeneralProblem.xlsx'
          );
        });
      });
      e.cancel = true;
    },
    closeAllMasterDetail() {
      this.$refs['data-grid'].instance.collapseAll(-1);
    },
    onLanguageSelect(lang) {
      this.selectedLanguage = lang;
      localStorage.dbLanguage = lang.code;
      this.getBoqData();
    },
    onRemoved(e) {
      if (e.row.data.type_id === 2) {
        axios
            .post(`delete-work-problem/${e.row.data.id}`)
            .then((response) => {
              this.showToast({
                variant: 'success',
                text: 'Veri başarıyla silindi.'
              });
              this.getBoqData();
            })
            .catch((err) => {
              this.showToast({
                variant: 'danger',
                text: err?.response?.data?.message || err?.message
              });
            });
      } else {
        axios
            .delete(`delete-general-problem/${e.row.data.id}`)
            .then((response) => {
              this.showToast({
                variant: 'success',
                text: 'Veri başarıyla silindi.'
              });
              this.getBoqData();
            })
            .catch((err) => {
              this.showToast({
                variant: 'danger',
                text: err?.response?.data?.message || err?.message
              });
            });
      }
    },
    onUpdated(e) {
      const {
        boq_name,
        section_name,
        sort_order_description,
        report_order_1,
        report_order_2,
        unit_id,
        report_order_unit_id,
        currency_id_1,
        currency_id_2,
        currency_id_3,
        sort_order,
        section_code,
        boq_code,
        report_order_coef,
        price1,
        price2,
        price3,
        quantity,
        discount
      } = e.data;
      axios
          .post(`edit-boq/${e.data.id}`, {
            boq_name,
            section_name,
            sort_order_description,
            report_order_1,
            report_order_2,
            unit_id,
            report_order_unit_id,
            currency_id_1,
            currency_id_2,
            currency_id_3,
            sort_order,
            section_code,
            boq_code,
            report_order_coef,
            price1,
            price2,
            price3,
            quantity,
            discount
          })
          .then((response) => {
            this.showToast({
              variant: 'success',
              text: 'Veri başarıyla güncellendi.'
            });
            this.getBoqData();
          })
          .catch((err) => {
            this.showToast({
              variant: 'danger',
              text: err?.response?.data?.message || err?.message
            });
          });
    },
    onInserting(e) {
      this.addPopupVisible = true;
    },
    onEditing(e) {
      this.newGeneralProblem.id = e.row.key;
      this.newGeneralProblem.actions = e.row.data.actions;
      const dd = this.dataProblemType.find(
          (a) => a.id == e.row.data.problem_id
      );
      if (dd !== undefined) {
        this.newGeneralProblem.problemGroup = dd.type_id;
        // this.newGeneralProblem.problemTypeFiltered = dd.type_id;
      }
      // this.newGeneralProblem.problemGroup = this.dataProblemType.find(a=>a.id == e.data.problem_id);
      this.newGeneralProblem.problemTypeFiltered = e.row.data.problem_id;
      this.newGeneralProblem.explanation = e.row.data.explanation;
      this.newGeneralProblem.resolved = e.row.data.is_resolved;
      this.newGeneralProblem.type = e.row.data.type;
      this.newGeneralProblem.type_id = e.row.data.type_id;
      console.log("this.newGeneralProblem.type_id");
      console.log(this.newGeneralProblem.type_id);
      this.addPopupVisible = true;
      e.event.preventDefault();
    },
    blobToBase64(blob) {
      return btoa(
          blob.reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
    },
    onInserted(e) {
      const {
        boq_name,
        section_name,
        sort_order_description,
        report_order_1,
        report_order_2,
        unit_id,
        report_order_unit_id,
        currency_id_1,
        currency_id_2,
        currency_id_3,
        sort_order,
        section_code,
        boq_code,
        report_order_coef,
        price1,
        price2,
        price3,
        quantity,
        discount
      } = e.data;

      axios
          .post('create-boq', {
            boq_name,
            section_name,
            sort_order_description,
            report_order_1,
            report_order_2,
            unit_id,
            report_order_unit_id,
            currency_id_1,
            currency_id_2,
            currency_id_3,
            sort_order,
            section_code,
            boq_code,
            report_order_coef,
            price1,
            price2,
            price3,
            quantity,
            discount
          })
          .then((response) => {
            this.showToast({
              variant: 'success',
              text: 'Veri başarıyla eklendi.'
            });
            this.getBoqData();
          })
          .catch((err) => {
            this.showToast({
              variant: 'danger',
              text: err?.response?.data?.message || err?.message
            });
          });
    },
    onValueChanged(e) {
    }
  },

};
</script>

<style lang="scss">
@import './tables.scss';


.dx-master-detail-cell {
  background-color: bisque !important;
}
</style>
